import { Theme } from 'utils/theme'
import { Color } from 'atomic'
import { BrandColor } from './colors'

export const theme: Theme = {
  typography: {
    primary: 'ASAP, Verdana'
  },
  color: {
    primary: BrandColor.Magenta,
    alert: BrandColor.Red,
    accessory: BrandColor.DarkMagenta,
    clientAccessory: BrandColor.DarkMagenta,
    doctorAccessory: BrandColor.Gray,
    warning: BrandColor.Yellow,
    success: BrandColor.Green,

    background: Color.White,
    footer: BrandColor.Gray
  }
}
